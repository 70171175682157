<template>
  <div class="app-container v" v-loading.fullscreen.lock="closing">
    <div class="head-container">
      <quick-select v-model="query.buyerErpId" filterable url="api/distributorErps/list" placeholder="下单账户" value-field="erpId" display-field="name" clearable @change="toQuery" class="filter-item" style="width: 180px;" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" style="width: 220px;" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="reQuery" />
      <template v-if="checkPermission(['WHOLESALE_ALL','WHOLESALE_PAY'])">
        <el-select v-model="query.payType" placeholder="支付方式" style="width: 120px;" class="filter-item" clearable @change="query.payStatus = null; toQuery();">
          <el-option v-for="(v, i) in payTypes" :key="i" :label="v" :value="i" />
        </el-select>
        <el-select v-model="query.payStatus" placeholder="付款状态" style="width:120px" class="filter-item" clearable @change="toQuery" :disabled="query.payType !== 0 && query.payType !== 1">
          <template v-for="(item,i) in payStatus[query.payType]">
            <el-option v-if="item.hidden !== true" :key="i" :label="item.label" :value="i"></el-option>
          </template>
        </el-select>
      </template>
      <el-select v-model="query.statusList" placeholder="订单状态" style="width:170px" class="filter-item" clearable @change="reQuery" multiple collapse-tags>
        <el-option v-for="item in status" :key="item.id" :label="item.label" :value="item.id"></el-option>
      </el-select>
      <el-select v-model="query.isMiniProgram" filterable clearable placeholder="操作来源" class="filter-item" style="width: 120px;" @change="toQuery">
        <el-option label="小程序" :value="true" />
        <el-option label="电脑端" :value="false" />
      </el-select>
      <quick-select v-model="query.shop" url="api/shop" placeholder="门店" clearable filterable @change="toQuery" value-field="shopName" display-field="shopName" class="filter-item" style="width: 180px;" />

      <el-input v-model="query.formCode" class="filter-item" :maxlength="20" placeholder="采购单号或ERP单号" @keypress.enter.native="reQuery" style="width: 160px;" />
      <el-input v-model="query.goodsName" class="filter-item" :maxlength="20" placeholder="商品ERP编码或名称" @keypress.enter.native="reQuery" style="width: 160px;" />
      <el-input v-model="query.goodsSeries" class="filter-item" :maxlength="20" placeholder="商品系列" @keypress.enter.native="reQuery" style="width: 120px;" />
      <el-input v-model="query.goodsSpec" class="filter-item" :maxlength="20" placeholder="商品规格" @keypress.enter.native="reQuery" style="width: 150px;" />
      <el-input v-model="query.customer" class="filter-item" :maxlength="20" placeholder="客户姓名" @keypress.enter.native="reQuery" style="width: 160px;" />
      <el-input v-model="query.contractNo" class="filter-item" :maxlength="20" placeholder="零售单/合同号" @keypress.enter.native="reQuery" style="width: 160px;" />
      <el-input v-model="query.info" class="filter-item" :maxlength="20" placeholder="备注" @keypress.enter.native="reQuery" style="width: 160px;" />
      <el-input v-model="query.promotionName" class="filter-item" :maxlength="40" placeholder="促销活动" @keypress.enter.native="reQuery" style="width: 160px;" />
      <el-input v-model="query.groupSaleName" class="filter-item" :maxlength="40" placeholder="组合套餐" @keypress.enter.native="reQuery" style="width: 160px;" />
      <div class="filter-item" style="line-height: 32px;">查看未出货商品:</div>
      <el-switch v-model="query.unsend" class="filter-item" @change="reQuery"></el-switch>

      <el-button size="mini" class="filter-item" type="success" icon="el-icon-search" @click="reQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-card shadow="never" v-if="count">
      <div class="h" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
        <div class="money-count-item">
          <div class="fc-g">原单金额</div>
          <counter class="num" :end-val="count.amount / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">销售金额</div>
          <counter class="num" :end-val="(count.amount - count.discountAmount) / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">使用返利金额</div>
          <counter class="num" :class="count.rebateAmount > 0 ? 'fc-e' : ''" :end-val="count.rebateAmount / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">优惠金额</div>
          <counter class="num" :class="count.discountAmount > 0 ? 'fc-e' : ''" :end-val="count.discountAmount / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">发货数量</div>
          <counter class="num" :end-val="count.sendCount" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">收货数量</div>
          <counter class="num" :end-val="count.receivedCount" :decimals="2" :duration="1000" />
        </div>
      </div>
    </el-card>

    <el-table border v-loading="loading" :data="data" :default-sort="{prop: 'purchaseTime', order: 'descending'}" @sort-change="handleSort" style="margin-top: 10px;" height="200px">
      <el-table-column prop="formCode" label="采购单号" width="120" sortable="custom" />
      <el-table-column prop="retailFormCode" label="零售单号" width="120" />
      <el-table-column prop="retailContractNo" label="零售合同号" width="100" show-overflow-tooltip />
      <el-table-column prop="shop" label="门店" width="100" show-overflow-tooltip />
      <el-table-column prop="customer" label="客户名称" width="100" show-overflow-tooltip />
      <!-- <el-table-column prop="erpFormCode" label="ERP单号" width="110" :formatter="v=>{ return v.formCode===v.erpFormCode?'':v.erpFormCode}"  /> -->
      <el-table-column prop="purchaseTime" label="订单日期" width="100" :formatter="r => { return new Date(r.purchaseTime).format('yyyy/MM/dd'); }" sortable="custom" />
      <!-- <el-table-column prop="buyerErpId" label="经销商编码" width="150" sortable="custom" />
      <el-table-column prop="buyerName" label="经销商" min-width="200" sortable="custom" show-overflow-tooltip />-->
      <el-table-column prop="goodsSeries" label="商品系列" min-width="100" sortable="custom" show-overflow-tooltip />
      <el-table-column label="商品编码" prop="code" width="160" sortable="custom" />
      <el-table-column label="ERP编码" prop="erpCode" width="160" sortable="custom" />
      <el-table-column label="商品" prop="goodsName" min-width="180" sortable="custom" />
      <el-table-column prop="goodsSpec" label="规格" min-width="160" :formatter="$goodsSpecConvert" sortable="custom" />
      <el-table-column label="数量" prop="count" sortable="custom" width="80" align="right" />
      <el-table-column label="出厂价" sortable="custom" width="100" align="right" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
        <template slot-scope="scope">{{$price(scope.row.price)}}</template>
      </el-table-column>
      <el-table-column label="折扣率" width="90" align="right" :formatter="calcProductDiscountPercent" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])" />
      <el-table-column label="单价(扣返利前)" prop="realPrice" sortable="custom" width="125" align="right" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
        <template slot-scope="scope">{{$price(scope.row.realPrice)}}</template>
      </el-table-column>
      <el-table-column label="单价(扣返利后)" width="125" align="right" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
        <template slot-scope="scope">{{$price(scope.row.realPrice-(scope.row.rebateAmount/scope.row.count))}}</template>
      </el-table-column>
      <el-table-column label="小计(扣返利前)" prop="amount" width="125" align="right" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
        <template slot-scope="scope">{{$price(scope.row.realPrice*(scope.row.count - scope.row.cancelCount))}}</template>
      </el-table-column>
      <el-table-column label="返利抵扣" prop="rebateAmount" sortable="custom" width="100" align="right" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
        <template slot-scope="scope">{{$price(scope.row.rebateAmount*(scope.row.count - scope.row.cancelCount)/scope.row.count)}}</template>
      </el-table-column>
      <el-table-column label="小计(扣返利后)" prop="amount" width="125" align="right" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
        <template slot-scope="scope">{{$price(scope.row.realPrice*(scope.row.count - scope.row.cancelCount)-scope.row.rebateAmount*(scope.row.count - scope.row.cancelCount)/scope.row.count)}}</template>
      </el-table-column>
      <el-table-column label="整单折扣率" width="90" align="right" :formatter="calcDiscountPercent" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])" />
      <el-table-column label="已发货数量" width="120" prop="sendCount" sortable="custom" />
      <el-table-column label="已收货数量" width="120" prop="receivedCount" sortable="custom" />
      <el-table-column label="已取消数量" width="120" prop="cancelCount" sortable="custom" />

      <!-- <el-table-column label="对应订单" width="80" align="center">
        <template slot-scope="scope">
          <el-tooltip placement="top" v-if="scope.row.source && scope.row.source.length">
            <div slot="content" style="max-width: 800px;">
              <b>对应订单</b>
              <template v-for="(s, i) in scope.row.source">
                <div :key="s">零售单号：{{scope.row.retailFormCode[i]}}，门店：{{scope.row.shop[i]}}，客户姓名：{{scope.row.customer[i]}}</div>
              </template>
            </div>
            <span>查看</span>
          </el-tooltip>
        </template>
      </el-table-column>-->

      <el-table-column label="发货日期" prop="shipDate" width="100" :formatter="v=>{return new Date(v.shipDate).format('yyyy/MM/dd')}" sortable="custom" />
      <el-table-column label="支付方式" width="100" prop="payType" :formatter="r => {return payTypes[r.payType];}" sortable="custom" />
      <el-table-column label="付款情况" align="center" width="105" prop="payStatus" sortable="custom" v-if="checkPermission(['WHOLESALE_ALL','WHOLESALE_PAY'])">
        <template slot-scope="scope">
          <el-tag :type="payStatus[scope.row.payType][scope.row.payStatus].type" :key="scope.row.id">{{payStatus[scope.row.payType][scope.row.payStatus].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="关闭状态" align="center" width="100" prop="status">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.count == scope.row.cancelCount">已关闭</el-tag>
          <el-tag type="info" v-else-if="0<scope.row.cancelCount && scope.row.cancelCount<scope.row.count">部分已关闭</el-tag>
          <el-tag v-else>未关闭</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" align="center" width="100" prop="formStatus" sortable="custom">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.formStatus].type">{{status[scope.row.formStatus].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作来源" prop="isMiniProgram" width="100" sortable="custom">
        <template slot-scope="scope">{{scope.row.isMiniProgram?"小程序":"电脑端"}}</template>
      </el-table-column>
      <el-table-column label="备注" prop="info" width="160" sortable="custom" />
      <el-table-column label="促销活动" prop="promotionName" width="120" sortable="custom" show-overflow-tooltip/>
      <el-table-column label="组合套餐" prop="groupSaleName" width="120" sortable="custom" show-overflow-tooltip/>      
      <el-table-column width="50" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">查看</el-button>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <e-form ref="form" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";
import { sumDetailsBuyer } from "@/api/distributorOrder";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import Counter from "vue-count-to";

export default {
  components: { eForm, Counter },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      delLoading: false,
      sort: "purchaseTime,desc",
      closing: false,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      payTypes: ["线上支付", "线下支付"],
      payStatus: [
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分付款成功",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "付款成功",
          },
        ],
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分已付款",
          },
          {
            type: "warning",
            label: "部分付款已确认",
          },
          {
            type: "success",
            label: "已付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "已完成",
          },
        ],
      ],
      status: [
        {
          type: "info",
          label: "编辑中",
          id: 0,
        },
        {
          type: "warning",
          label: "已提交",
          id: 1,
        },
        {
          type: "success",
          label: "已确认",
          id: 2,
        },
        {
          type: "danger",
          label: "已拒绝",
          id: 3,
        },
        //
        {
          type: "warning",
          label: "待发货",
          id: 4,
        },
        {
          type: "success",
          label: "部分已发货",
          id: 5,
        },
        {
          type: "success",
          label: "已发货",
          id: 6,
        },
        {
          type: "success",
          label: "部分已收货",
          id: 7,
        },
        {
          type: "success",
          label: "已完成",
          id: 8,
        },
        {
          type: "info",
          label: "已关闭",
          id: 9,
        },
        {
          type: "info",
          label: "已取消",
          id: 10,
        },
        {
          type: "info",
          label: "部分已关闭",
          id: 11,
        },
      ],
      count: null,
      query: {
        dateRange: null,
        payStatus: null,
        status: null,
        formCode: null,
        goodsName: null,
        unsend: false,
      },
    };
  },
  created() {
    this.reQuery();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/orderForm/detail/buyer";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign({ sort: this.sort }, query);
      return true;
    },
    afterLoad(data) {
      (data || []).forEach((o) => {
        o.source = o.source ? o.source.split(",") : [];
        o.retailFormCode = o.retailFormCode ? o.retailFormCode.split(",") : [];
        o.shop = o.shop ? o.shop.split(",") : [];
        o.customer = o.customer ? o.customer.split(",") : [];
      });
    },
    handleSort(sort) {
      if (sort.order == null) this.sort = "";
      else
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      this.toQuery();
    },
    loadSums() {
      sumDetailsBuyer(this.params).then((res) => {
        this.count = res;
      });
    },
    reQuery() {
      this.toQuery();
      this.loadSums();
    },
    calcProductDiscountPercent(row) {
      let dm = row.price - row.realPrice;
      if (dm <= 0) return "100%";
      else return ((row.realPrice / row.price) * 100).toFixed(2) + "%";
    },
    calcDiscountPercent(row) {
      let dm = row.formTotalMoney - row.formRealTotalMoney;
      if (dm <= 0) return "100%";
      else
        return (
          ((row.formRealTotalMoney / row.formTotalMoney) * 100).toFixed(2) + "%"
        );
    },
    edit(data) {
      this.$refs.form && this.$refs.form.resetForm(data.formId);
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/purchase/download", this.params)
        .then((result) => {
          downloadFile(result, "采购明细", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>